<template>
	<div>
		<div class="pd30">
			<div >
			  <a-button type="primary" icon="plus" @click="addCustomerAct()">添加客户</a-button>
			</div>
			
			<div class="mt30">
				<div class="wxb-table-gray">
					<a-table rowKey="member_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
						<div class="flex alcenter center" slot="money" slot-scope="money,record">
							{{money}}元
						</div>
					</a-table>
				</div>
			</div>
			
			<div>
				<a-modal title="添加客户" :width="540" :visible="visible"  @cancel="handleCancel" >
					<template slot="footer" >
						<a-button  key="back"  @click="handleCancel">
							取消
						</a-button>
						<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
							保存
						</a-button>
					</template>
					<a-spin :spinning="loading">
						<div>
							<a-input class="woyaou-input " prefix="手机号" style="width: 480px;" placeholder="请输入客户手机号" v-model="form.mobile" :maxLength="20">
							</a-input>
						</div>
					</a-spin>
				</a-modal>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			partner_id:{
				type:Number,
				default:0
			},
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				visible:false,
				confirmLoading:false,
				form:{
					mobile:'',
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '客户昵称',dataIndex: 'nick_name',align: 'center',ellipsis: true},
					{title: '客户手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '客户消费金额',dataIndex: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
				],
				datas: [],
			}
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getPartnerCustomerList',{
					partner_id:this.partner_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					role_id:1,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			addCustomerAct(){
				this.visible=true;
			},
			
			handleCancel(){
				this.visible=false;
			},
			
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/bindMemberPartner',{
					partner_id:this.partner_id,
					role_id:1,
					mobile:this.form.mobile,
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.visible=false;
						this.getLists();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
